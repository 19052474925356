import React, { useState, useEffect} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import SanitizeHtmlComponent from './SanitizeHtmlComponent';
import { IMAGE_BASE_URL } from '../constants/paths';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import Moment from 'react-moment';
import { CircularProgress } from '@material-ui/core';
import TopNavbar from '../components/Nav/TopNavbar';
import { Helmet } from "react-helmet-async";
import Footer from '../components/Sections/Footer';





const Posts =()=>{

    const[blogPosts, setBlogPosts] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(()=>{

        const getPosts = async()=>{
            setIsLoading(true);
            const res = await makeGetRequest('blog-posts');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                setBlogPosts(res.data)        
                setIsLoading(false);
                setError(null)
    
            }
           
        }
        getPosts()

    },[])


    const navigate = useNavigate();

  


    const showPost=(post)=>{
        return navigate('/blog',{state:post})

    }

  

    if(isLoading){
        return <CircularProgress/>
    }
    if(error){
        return <p className='text text-danger'>{error}</p>
    }

   

   
    return(
        <>
          <Helmet>
          <title>CS Blog</title>
    <meta name="description" content='Latest from CS church management and accounting software blog'/>
     <link rel='canonical' href='/blog'/>
  </Helmet>
        <TopNavbar returnTo={true}/>
        <div className='container text text-center'>
        <h1>Blog</h1>
        {error!==null && <p className='text text-danger'>{error}</p>}
        {
            blogPosts.length !==0?
            blogPosts.map((post)=>{
                return <div className='row' key={post.id} style={{marginBottom:"25px"}}>
                    <div className='col-md-5'>
                        <Link to={`/blog/${post.slug}/${post.id}`}>
                        <img crossOrigin="anonymous" src={IMAGE_BASE_URL+post.featuredImage} alt={post.title} style={{ width: '100%', height: '400px' }}/>
                        </Link>
                        </div>
                    <div className='col-md-5' style={{ border:"1px solid grey",borderLeft:"none"}}>
                        <Card>
                           
                            <Card.Body style={{margin:"30px"}}>
                                <div style={{fontSize:"12px", alignItems:"left", display:"flex", marginBottom:"25px"}}>
                                    <div style={{marginRight:"22px"}}>
                                        <img src={post.author !== null && post.author.avatar} alt='avatar' width={50} height={50} className="rounded-circle"/>

                                    </div>
                                    <div>
                                    <p style={{marginBottom:"0px"}}>{post.author!== null && post.author.first_name} {post.author !== null && post.author.last_name}</p>
                                    <p><Moment fromNow>{post.createdAt}</Moment></p>

                                    </div>
                                  

                                </div>
                           <Link to={`/blog/${post.slug}/${post.id}`}>
                            <h4>{post.title}</h4>
                            </Link>
                               <Link to={`/blog/${post.slug}/${post.id}`}>
                                <div style={{fontFamily:"sans-serif times new roman", fontSize:"20px"}}>{<SanitizeHtmlComponent htmlContent={post.content} maxContentLength={230}/>}</div>
                                </Link>
                                
                                <hr/>
                            </Card.Body>
                            <Card.Footer>
                                <div style={{display:"flex"}}>
                                    
                                       <div style={{marginRight:"130px"}}> {post.comments.length} comments</div>
                                       <div style={{marginLeft:"130px"}}>{2} <FaHeart/></div>                                  

                                    
                                   
                                </div>
                            </Card.Footer>

                        </Card>
                        </div>

                        <div className='col-md-4'>
                        </div>
                </div>
            })
            :
            <p className='text text-info'>No Posts</p>
        }
    </div>
        <Footer/>
        </>
    )



}
export default Posts;
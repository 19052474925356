import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets

import ActivityLog from "../../assets/img/activitylog.png"
import { makeGetRequest } from "../../services/httpHelper";
import ErrorHandler from "../../services/ErrorHandler";
import { IMAGE_BASE_URL } from '../../constants/paths';
import { Helmet } from 'react-helmet';


export default function Projects() {

  const[blogPosts, setBlogPosts] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [feedBacks,setFeedBacks] = useState([]);

    const navigate = useNavigate();

  const getPosts = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest('blog-posts');
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
        setBlogPosts(res.data)        
        setIsLoading(false);
        setError(null)

    }
   
}



   
  


const showPost=(post)=>{
    return navigate(`/blog/${post.slug}/${post.id}`)

}
const showPosts=(post)=>{
  return navigate('/blog')

}

useEffect(()=>{
    getPosts();

},[])


  return (
    <>
    <Helmet>
<title>CS Church Management and Accounting Software Blog and News</title>
<meta name="description" content="Latest articles from our blog to help you set up your CS church management and accounting software account, get insights on church administration, and fund accounting"/>
<link rel='canonical' href='/'/>


</Helmet>
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font30 extraBold" style={{textAlign:"left"}}>CS Church Management and Accounting Software Blog Articles, news and tutorials</h1>
            <p className="font13">
            Latest articles from our blog to help you set up your CS church management and accounting software account, get insights on church administration, and fund accounting          
            </p>
          </HeaderInfo>
          <div className="row textCenter">

          {
  blogPosts.length !== 0 ?
  blogPosts.slice(0, 6).map((post, index) => {
    return (
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={post.id}>
        <ProjectBox
          img={IMAGE_BASE_URL + post.featuredImage}
          title={post.title}
          text=""
          action={() => showPost(post)}
          slug={post.slug}
          postid={post.id}

        />
      </div>
    );
  })
  :
  <p>No Posts</p>
}
         
         
           
          </div>
        
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => showPosts()} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={ActivityLog} alt="add" style={{width:426,height:607}} />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">Start Your 30-Day Free Trial Today!</h2>
              <p className="font16">
              Experience the transformative power of CS Church Management and Accounting Software with our risk-free 30-day trial. Join thousands of churches worldwide who trust CS to simplify their administrative tasks and empower their ministries. Sign up now and embark on a journey of efficiency, transparency, and growth!
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <a href="https://cs-dashboard.roshtech.co.ke/signup" target="_blank">
                  <FullButton title="Get Started"  />
                  </a>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                 
                  <a activeClass="active" style={{ padding: "10px 15px" }} href="/#contact" spy={true} smooth={true} offset={-80}>
                  <FullButton title="Contact Us" border />
              </a>

                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

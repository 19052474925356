import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import FAQs from "../components/Sections/FAQs"
import Services from "../components/Sections/Services";
import { Helmet } from "react-helmet";



export default function Landing() {
  return (
    <>
    <Helmet>
                <title>CS Church Management Software</title>
                <meta name="description" content="CS Church Management and Accounting Software"/>
                <link rel='canonical' href='/'/>

              
            </Helmet>
     <TopNavbar />
      <Header />
       <Services />
      <FAQs/>
      <Pricing />  
      <Blog />
      <Contact />
      <Footer />
    </>
  );
}



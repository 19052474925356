import React,{useState,useEffect} from 'react';
import { Link, } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import DOMPurify from "dompurify";
import SanitizeHtmlComponent from '../SanitizeHtmlComponent';
import TopNavbar from '../../components/Nav/TopNavbar';
import Footer from '../../components/Sections/Footer';
import { Helmet } from 'react-helmet-async';


 

const PrivacyPolicy = ()=>{
    
   
        const [policy,setPolicy] = useState(null);
        const [isLoading,setIsLoading] = useState(false);
        const [serverError,setServerError] = useState(null)
 
        useEffect(()=>{
            const getPolicy = async()=>{
                setIsLoading(true);
                const res = await makeGetRequest('policy/privacy');
                if (res && res.errorType) {
                    setIsLoading(false);
                    const errorMessage = ErrorHandler(res)
                    setServerError(errorMessage)
        
                } else {
                    const result = await res.data;
                    console.log(result)
                    setPolicy(result)        
                    setIsLoading(false);
                    setServerError(null)
        
                }
               
            }
            getPolicy();
            },[])
            if(isLoading){
                return <CircularProgress/>
            }
   

    return(
        <>
           <Helmet>
                <title>Privacy Poclicy</title>
                <meta name="description" content="Privacy policyThis page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service." />
                <link rel="canonical" href="/privacy" />
                <meta property="og:title" content='Privacy Policy' />
                          
                <meta property="og:type" content="article" />
            </Helmet>
        <TopNavbar/>
        <div className="blog-post-container">
        <div className="container">
        {serverError !== null && <p className='text text-danger'>{serverError}</p>}

            {
                policy !== null ?
                                 
                     

<main role="main">
    <div className="row">
        <div className="col-md-3">
       
        </div>
        <div className="col-md-9">
        <article>
    <section className="introduction">
        <br/><br/>

    {/*<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(policy.description) }} />*/}
    {<SanitizeHtmlComponent htmlContent={policy.description} />}

        </section>        
       
    </article>

        </div>
    </div>
     

</main>

                
                :
               
                <div className="container">
                <h3>Nothing to show</h3>
                </div>


            }
           
        
   
        </div>

        </div>
        <Footer/>
       
        </>
       
    )
        }


export default PrivacyPolicy;


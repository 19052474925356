import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import { FaAlgolia, FaBuilding, FaChartBar, FaDollarSign, FaDonate, FaHandsHelping, FaListAlt, FaMoneyBill, FaPeopleCarry, FaRecordVinyl, FaUsers } from "react-icons/fa";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "users":
      getIcon = <FaUsers style={{fontSize:'50px',textAlign:'center', color:'orange'}}/>;
      break;
    case "accounting":
      getIcon = <FaDollarSign style={{fontSize:'50px',textAlign:'center', color:'red'}} />;
      break;
    case "contribution":
      getIcon = <FaDonate style={{fontSize:'50px',textAlign:'center', color:'blue'}} />;
      break;
    case "report":
      getIcon = <FaChartBar style={{fontSize:'50px',textAlign:'center', color:'purple'}} />;
      break;

      case "tracking":
      getIcon = <FaRecordVinyl style={{fontSize:'50px',textAlign:'center', color:'green'}} />;
      break;

      case "budget":
        getIcon = <FaBuilding style={{fontSize:'50px',textAlign:'center', color:'amber'}} />;
        break;

        case "volunteer":
        getIcon = <FaHandsHelping style={{fontSize:'50px',textAlign:'center', color:'olive'}} />;
        break;

        case "group":
        getIcon = <FaPeopleCarry style={{fontSize:'50px',textAlign:'center', color:'#008080'}} />;
        break;
    default:
      getIcon = <RollerIcon />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
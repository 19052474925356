import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import DashboardImage from "../../assets/img/dashboard2_426x607.png";

import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { BASE_URL , IMAGE_BASE_URL} from "../../constants/paths";
import { Link, Navigate,useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";


export default function Header() {

  return (
    <>
  <Helmet>
    <meta name="description" content=" Our software empowers churches to manage members, finances, fund accounting, events, and communication with ease and efficiency. Enhance your community engagement, streamline administrative tasks, and focus more on your ministry with our comprehensive, user-friendly tools. Discover how CS Church Management Software can transform your church's operations today."/>
     <link rel='canonical' href='/'/>


  </Helmet>
<Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h3 className="extraBold font60">Welcome to <span style={{color:'orange'}}>CS</span> Church Management  Software, the ultimate solution for modern church operations</h3>
          <HeaderP className="font13 semiBold">
          
            Our software empowers churches to manage finances, manage members, events, contributions, pledges, attendance, services and communication with ease and efficiency. Enhance your community engagement, streamline administrative tasks, and focus more on your ministry with our comprehensive, user-friendly tools. Discover how CS Church Management Software can transform your church's operations today
          </HeaderP>
          <BtnWrapper>
            <a href="https://cs-dashboard.roshtech.co.ke/signup" target="_blank"><FullButton title="START FOR FREE"/></a>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={DashboardImage} alt="office" style={{zIndex: 9,width:'426px',height:'607px'}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Its simple yet rich in features good for churches of all sizes. What I loved most about this software is that its designed to lower adoption costs but still enjoy the benefits that comes with installing expensive assets like cameras in the Church</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Bishop Peter Wekesa</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
    
    </>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;



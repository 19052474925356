import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import SanitizeHtmlComponent from './SanitizeHtmlComponent';
import './Post.css';
import { FaComment, FaHeart } from 'react-icons/fa';
import Moment from 'react-moment';
import { IMAGE_BASE_URL } from '../constants/paths';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import TopNavbar from '../components/Nav/TopNavbar';
import { Helmet } from 'react-helmet-async';
import SocialShare from '../components/SocialShare';
import Footer from '../components/Sections/Footer';


const Post = () => {
    const [post, setPost] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();
    const currentUrl = window.location.href;

    const getPost = async () => {
        setIsLoading(true);
        const res = await makeGetRequest(`blog-post/${id}`);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setPost(res.data);
            setIsLoading(false);
            setError(null);
        }
    };

    const getRelatedArticles = async () => {
        setIsLoading(true);
        const res = await makeGetRequest(`related-posts/${id}`);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setRelatedPosts(res.data);
            setIsLoading(false);
            setError(null);
        }
    };

    useEffect(() => {
        getPost();
        getRelatedArticles();
    }, [id]);

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const extractDescription = (htmlContent, wordLimit) => {
        const textContent = stripHtmlTags(htmlContent);
        return textContent.split(' ').slice(0, wordLimit).join(' ') + '...';
    };

    const postDescription = post ? extractDescription(post.content, 20) : '';

    const metaDesc = `${post ? post.title : ''}`;
    const metaLink = `/blog/${post ? post.slug : ''}/${id}`;
    const metaImage = post && post.featuredImage ? `${IMAGE_BASE_URL}${post.featuredImage}` : '';

    return (
        <>
            <Helmet>
                <title>{post ? post.title : 'CS Church Management Software'}</title>
                <meta name="description" content={metaDesc} />
                <link rel="canonical" href={metaLink} />
                <meta property="og:title" content={post ? post.title : ''} />
                <meta property="og:description" content={postDescription} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="article" />
            </Helmet>
            <TopNavbar returnTo={true} />
            <div className='container post-wrapper' style={{ fontSize: "25px" }}>
                {post !== null ?
                    <>
                        {post.featuredImage !== null &&
                            <article className="hentry">
                                <div className="featured-image">
                               {/* <img width="250px" height="250px" src={IMAGE_BASE_URL + post.featuredImage} alt={post.title} className='post-img' crossOrigin="anonymous" />*/}
                                </div>

                                <h1 className="entry-title">{post.title}</h1>
                                <div className="entry-meta">
                                    <p><span className="author">Written by <a href="#">{post.author !== null && post.author.first_name} {post.author !== null && post.author.last_name}</a></span> <span className="date"> <Moment fromNow>{post.createdAt}</Moment></span></p>
                                </div>
                                <div className="entry-content">
                                    {<SanitizeHtmlComponent htmlContent={post.content} />}
                                </div>

                                <footer className="entry-footer">
                                    <br />
                                    <div style={{ display: "flex", alignItems: "right", marginBottom: "20px", width: "100%" }}>
                                        <SocialShare title={post.title} shareUrl={currentUrl} />
                                    </div>
                                </footer>
                            </article>
                        }
                        <h5>Related</h5>
                       
                        {relatedPosts.length !== 0 &&
                            relatedPosts.map((relatedPost) => (
                                <div className='row' key={relatedPost.id} style={{ marginBottom: "25px" }}>
                                    <div className='col-md-10'>
                                        <Link to={`/blog/${relatedPost.slug}/${relatedPost.id}`}>
                                            <h4>{relatedPost.title}</h4>
                                        </Link>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            ))
                        }
                    </>
                    :
                    <p className='text text-warning'>Nothing to show</p>
                }
            </div>
            <Footer/>
        </>
    );
};

export default Post;

import React,{useState,useEffect} from 'react';
import { CircularProgress } from '@material-ui/core';
import DOMPurify from "dompurify";
import { makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import SanitizeHtmlComponent from '../SanitizeHtmlComponent';
import Footer from '../../components/Sections/Footer';
import TopNavbar from '../../components/Nav/TopNavbar';
import { Helmet } from 'react-helmet-async';


const Terms =()=>{

    const [terms,setTerms] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null)

    useEffect(()=>{
        const getTerms = async()=>{
            setIsLoading(true);
            const res = await makeGetRequest('policy/terms');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                setTerms(res.data)        
                setIsLoading(false);
                setServerError(null)
    
            }
           
        }
        getTerms();
        },[])
    return(
        <>
          <Helmet>
                <title>Terms</title>
                <meta name="description" content="These terms and conditions outline the rules and regulations for the use of CS Church Management Software’s Website and Software, located at https://www.cs-dashboard.roshtech.co.ke.
By accessing this website, we assume you accept these terms and conditions. Do not continue to use the software if you do not agree to take all of the terms and conditions stated on this page" />
                <link rel="canonical" href="/terms" />
                <meta property="og:title" content='Terms' />
                         
                <meta property="og:type" content="article" />
            </Helmet>
        <TopNavbar/>
        <div className="blog-post-container">
        <div className="container">
        {serverError !== null && <p className='text text-danger'>{serverError}</p>}

            {
                terms !== null ?
                                   

<main role="main">
    <div className="row">
        <div className="col-md-3">
       
        </div>
        <div className="col-md-9">
        <article>
    <section className="introduction">
    <br/><br/>

    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terms.description) }} />
        </section>        
       
    </article>

        </div>
    </div>
     

</main>

                
                :
                <div className="container">
                    <CircularProgress color="secondary"/>
                    </div>
               
 


            }
           
        
   
        </div>

        </div>

        <Footer/>
       
        </>
    )
}

export default Terms;
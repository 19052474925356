import React from "react";
import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import 'bootstrap/dist/css/bootstrap.css';
import Posts from "./screens/Posts.jsx";
import Post from "./screens/Post.jsx";
import FAQs from "./components/Sections/FAQs.jsx";
import NoInternet from "./services/NoInternet.js";
import Terms from "./screens/policies/Terms.jsx"
import PrivacyPolicy from "./screens/policies/PrivacyPolicy.jsx"
import Services from "./components/Sections/Services.jsx";
import Pricing from "./components/Sections/Pricing.jsx";
import Contact from "./components/Sections/Contact.jsx";




function App() {

  return(

    <NoInternet>
      <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
     
      <Router>
       
      <Routes>
      <Route exact path="*" element={<Landing/>}/>
      <Route exact path="/" element={<Landing/>}/>
     <Route exact path="/blog" element={<Posts/>}/>
     <Route exact path="/blog/:slug/:id" element={<Post/>}/>
     <Route exact path="/faqs" element={<FAQs/>}/>
     <Route exact path="/services" element={<Services/>}/>
     <Route exact path="/pricing" element={<Pricing/>}/>
     <Route exact path="/contact-us" element={<Contact/>}/>

     <Route exact path="/terms" element={<Terms/>}/>
     <Route exact path="/privacy" element={<PrivacyPolicy/>}/>

       

      
      </Routes>
  
    </Router>
      
      
      </>


    </NoInternet>

  );

  
 
}

export default App;


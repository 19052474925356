import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import './SocialShare.css';

const SocialShare = ({ title, shareUrl, imageUrl }) => {
  return (
    <div className="socialShareContainer">
      <div className="SocialShare__network">
        <FacebookShareButton url={shareUrl} quote={title} className="SocialShare__network__share-button">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <FacebookShareCount url={shareUrl} className="SocialShare__network__share-count" />
      </div>

      <div className="SocialShare__network">
        <TwitterShareButton url={shareUrl} title={title} className="SocialShare__network__share-button">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>

      <div className="SocialShare__network">
        <TelegramShareButton url={shareUrl} title={title} className="SocialShare__network__share-button">
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>

      <div className="SocialShare__network">
        <WhatsappShareButton url={shareUrl} title={title} separator=":: " className="SocialShare__network__share-button">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div className="SocialShare__network">
        <LinkedinShareButton url={shareUrl} className="SocialShare__network__share-button">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className="SocialShare__network">
        <PinterestShareButton url={shareUrl} media={imageUrl} className="SocialShare__network__share-button">
          <PinterestIcon size={32} round />
        </PinterestShareButton>
        <PinterestShareCount url={shareUrl} className="SocialShare__network__share-count" />
      </div>

      <div className="SocialShare__network">
        <RedditShareButton url={shareUrl} title={title} className="SocialShare__network__share-button">
          <RedditIcon size={32} round />
        </RedditShareButton>
        <RedditShareCount url={shareUrl} className="SocialShare__network__share-count" />
      </div>

      <div className="SocialShare__network">
        <EmailShareButton url={shareUrl} subject={title} body="body" className="SocialShare__network__share-button">
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default SocialShare;

import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets

import LandingPage from "../../assets/img/landingpage.png"
import barChartImage from "../../assets/img/barchart.png"
import budgetImage from "../../assets/img/budget.png"
import givingImage from "../../assets/img/giving.png"
import chartImage from "../../assets/img/chart.png"
import { Helmet } from "react-helmet-async";




import { FaCheck, FaUsers } from "react-icons/fa";
import { Navigate } from "react-router-dom";


export default function Services() {
  return (
    <>

<Helmet>
               <title>CS Church Management Software Services, features and benefits</title>
    <meta name="description" content=" CS church management and accounting software is meticulously designed to cater to every aspect of church administration, allowing you to focus more on your ministry.
    "/>
     <link rel='canonical' href='/services'/>


  </Helmet>
    <Wrapper id="services">
      {/*
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>*/}
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font30 extraBold" style={{textAlign:"left"}}>You can trust us as your church management software?</h1>
            <p className="font17">
            At CS, we understand the unique needs of churches in today's digital age. Our cloud-based management and accounting software is meticulously designed to cater to every aspect of church administration, allowing you to focus more on your ministry and less on paperwork.
              <br />
            </p>
            <br/>
            <a href="https://cs-dashboard.roshtech.co.ke/signup" target="_blank"><FullButton title="TRY ALL FEATURES FOR FREE"/></a>


          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon='users'
                title="Member Tracking and Management"
                subtitle="Keep detailed records of your congregation effortlessly, ensuring personalized care and communication.Easily manage and organize church members' information and activities in one central place."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="accounting"
                title="Full Fund Accounting"
                subtitle="Track and manage church finances with precision and transparency.Gain complete control over your church's finances with comprehensive accounting tools integrated
                 "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="contribution"
                title="Pledges & Member Contributions"
                subtitle="Easily manage pledges and track member contributions, promoting financial transparency and accountability."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="report" title="Financial Statements" 
              subtitle="Generate detailed financial statements with ease, ensuring clarity and compliance to  gain insights and make informed decisions." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>


          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="tracking"
                title="Attendance Tracking"
                subtitle="Keep track of atteances in all church meetings and events. Generate personalized attendance reports for each member for any time range."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="budget"
                title="Budgeting"
                subtitle="Plan and manage church finances effectively to achieve financial goals and support ministries.
                 "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="volunteer"
                title="Volunteer Management"
                subtitle="Engage volunteers in your ministry and see how each volunteer is impacting your ministry. Organize and coordinate volunteers effectively for various church activities and events."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="group" title="Church Groups" 
              subtitle="Manage each group separately and gain deeper data insights on how each church group is performing. Wether is Women's ministry group, Youth ministry Group or any Church group we got you covered." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>



        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddRight >
              <br/>

                <div style={{marginLeft:'20px'}}>
                <h2 className="font35 extraBold">Do even more with CS Church Management and Accounting Software</h2>
                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Cradle Management:</strong>Record and cherish the growth of your church family, from the very beginning.
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Event Management:</strong>Organize and coordinate church events seamlessly, maximizing participation and engagement.
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Volunteer Management:</strong>Mobilize your congregation's talents effectively, fostering a culture of service and community.
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Attendance Tracking:</strong>Monitor attendance trends and analyze engagement levels effortlessly, empowering data-driven decision-making.
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Groups & Role-Based Access:</strong>Organize church groups efficiently and control access levels securely, optimizing collaboration and confidentiality.
                </p>
                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Notifications:</strong>Stay connected with your congregation through email, in-app, and SMS notifications, ensuring timely communication
                </p>
                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Website Builder</strong>Create a stunning online presence for your church with our intuitive website builder, enhancing visibility and engagement
                </p>
                </div>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px "}}>
                  <div style={{ width: "190px" }}>

                    <a href="https://cs-dashboard.roshtech.co.ke/signup" target="_blank"><FullButton title="START FREE TRIAL" /></a>
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    

                    <a activeClass="active" style={{ padding: "10px 15px" }} href="/#contact" spy={true} smooth={true} offset={-80}>
                    <FullButton  title="REQUEST DEMON"  border/>
              </a>
                  </div>
                </ButtonsRow>
              </AddRight>
              <AddLeft >
                <AddRightInner >
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={budgetImage} alt="office" style={{width:'285px',height:'406px'}} />
                    </AddImgWrapp1>
                    <AddImgWrapp2 >
                      <img src={barChartImage} alt="office" style={{width:'197px',height:'333px',}} />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={givingImage} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={chartImage} alt="office" style={{width:'197px',height:'224px'}} />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddLeft>
            </Advertising>
          </div>
        </div>

        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
            

                <h2 className="font35 extraBold">Free forever professional Website for your Church</h2>
                <p className="font17">
                Enjoy a complimentary  website for your church upon subscription, boosting your online presence instantly. You can easily customize its look and feel from the your admin dashboard.
           

              
                </p>
                <h3> Free Website ships with the following benefits:</h3>               
                <p><strong> <FaCheck style={{color:'#008080'}}/> Receive Online Donations:</strong> Allow members to donate securely online, increasing convenience and accessibility.</p>
                <p><strong><FaCheck style={{color:'#008080'}}/> Post Sermons:</strong> Share sermons in various formats (videos, text, audio) to reach and inspire members.</p>
                <p><strong> <FaCheck style={{color:'#008080'}}/> Share Church Programs and Events:</strong> Keep members informed about weekly programs, events, and announcements.</p>
                <p><strong> <FaCheck style={{color:'#008080'}}/> Church Portfolio:</strong> Showcase the church's activities, including photo and video galleries, community engagements, missions, and doctrines, fostering transparency and engagement within the community.</p>
              
             

                
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                  <a href="https://cs-dashboard.roshtech.co.ke/signup" target="_blank"><FullButton title="START FREE TRIAL" /></a>
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                  <a activeClass="active" style={{ padding: "10px 15px" }} href="/#contact" spy={true} smooth={true} offset={-80}>
                    <FullButton  title="REQUEST DEMON"  border/>
              </a>

                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={LandingPage} alt="free-church-website" style={{width:'285px' ,height:'406px'}}/>
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={LandingPage} alt="office" style={{width:'197px', height:"333px"}}/>
                    </AddImgWrapp2>
                  </div>
               
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>


        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddRight >
              <br/>

                <div style={{marginLeft:'20px'}}>
                <h2 className="font35 extraBold">Why Choose CS Church Management?</h2>
                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Tailored Solutions: :</strong>Our cloud-based management and accounting software is meticulously designed to meet the specific needs of churches, ensuring seamless integration and optimal performance
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Comprehensive Features:</strong>From member tracking and event management to financial accounting and website building, our software offers a wide range of features to streamline every aspect of church administration.
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> User-Friendly Interface:</strong>We prioritize usability and accessibility, ensuring that our software is intuitive and easy to navigate for users of all skill levels.
                </p>

                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Dedicated Support:</strong>Our customer support team is here to assist you every step of the way, providing personalized assistance and guidance to help you maximize the benefits of our software
                </p>

              
                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Commitment to Security:</strong>We understand the importance of data security, which is why we employ industry-standard encryption and security protocols to keep your church's data safe and protected at all times
                </p>
                <p className="font17">
                  <strong> <FaCheck style={{color:'#008080'}}/> Affordable Rates: </strong>You pay less and get more. We understand small churches and we help them grow. We believe in Church mission and we are dedicated to support it.
                </p>
                </div>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px "}}>
                  <div style={{ width: "190px" }}>
                  <a href="https://cs-dashboard.roshtech.co.ke/signup" target="_blank"><FullButton title="START FREE TRIAL" /></a>
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                  <a activeClass="active" style={{ padding: "10px 15px" }} href="/#contact" spy={true} smooth={true} offset={-80}>
                    <FullButton  title="REQUEST DEMON"  border/>
              </a>

                  </div>
                </ButtonsRow>
              </AddRight>
              <AddLeft >
                <AddRightInner >
               <h5>
               Our mission  is to empower churches with cutting-edge technology that simplifies administrative tasks, fosters transparency, and facilitates growth.<br/>
                <span style={{color:'#008080',fontWeight:'bold'}}>
                We understand the unique challenges churches face in managing their operations, and we are committed to providing solutions that enable you to focus more on your ministry and less on paperwork.
                </span>
               </h5>
              
                </AddRightInner>
              </AddLeft>
            </Advertising>
          </div>
        </div>



      
      </div>
    </Wrapper>
    
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
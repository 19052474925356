const getVisitorCountry = () => {
    return new Promise((resolve, reject) => {
      window
        .fetch("https://ip2c.org/self")
        .then((response) => response.text())
        .then((data) => {
          const [status, country] = String(data).split(";");
          if (status !== "1") {
            throw new Error("Unable to fetch country");
          }

          resolve(country);
          return country;
        })
        .catch(() => {
          resolve("US");
        });
    });
  };

  export default getVisitorCountry;
import React,{useState,useEffect} from "react";
import styled from "styled-components";
// Components
import {makeGetRequest} from "../../services/httpHelper"
import ErrorHandler from "../../services/ErrorHandler";
import { FaCheck, FaWindowClose } from 'react-icons/fa';
import {toTitleCase} from '../../utils/myFunctions'


const addOns ={
  starter:[
    "True fund accounting",
    "financial statements",
    "free profesional website",
    "crowd funding & online giving",
    "unlimited members",
    "unlimited users",         
    "announcements",
    "church leadership tracking and management",    
    "email notification",
    "in App notification",
    "Bank reconciliation",
    "Print PDF reports",
    "export to CSV & Excel",
    "send emails to members",
    "send SMS (On special arrangement)",
    "in App notifications",    
    "SMS communication on pay as you terms",
    "website photo and video galleries",
    "church event announcements",
    "posting of sermons",
    "church statement of faith",
    "church FaQs",
    "sharing of scriptures",
    "church blog page",
    "export to excel",
    "export to CSV",
    "download PDFs",
    "bank reconciliation",
    "print reports"
    
  


  ],
  standard:[
    "church leadership management",
    "church groups management",
    "pledge metrics & stats",
    "bill stats"
   

  ],
  premium:[
    "import from excel",  
    "contribution statements",
    "member statements",
    "projects and campaigns",
    "skill profile",
    "role based access control",


  ]
}


const PricingFeatures =()=>{

    const [plan, setPlan] = useState('free');
    const [plans,setPlans] = useState([]);
      const [isLoading,setIsLoading] = useState(false);
      const [serverError,setServerError] = useState(null);
  
  
 
  
    const getPlans = async()=>{
      setIsLoading(true);
      const res = await makeGetRequest('subscription-plans');
      if (res && res.errorType) {
          setIsLoading(false);
          const errorMessage = ErrorHandler(res)
          setServerError(errorMessage)
  
      } else {
        const results = await res.data;
        
          setPlans(results)        
          setIsLoading(false);
          setServerError(null)
          
  
      }
     
  }
  
  
  
    useEffect(()=>{
      getPlans();
  
    },[])

    return(
      <div style={{marginTop:'5%'}}>
        <table className='table'>
          <thead>
            <tr style={{backgroundColor:'#008080', color:"#fff", fontSize:'24px', fontWeight:'bold' ,textAlign:"left",padding:'15px'}}> 
              <th></th>
              {
                
                plans && plans.length !==0 ?
                plans.map((plan)=>{
                  return <th key={plan.id}>{toTitleCase(plan.plan)}</th>
                })
                :
                <span>No Plans so far</span>
                
              }
            
            </tr>
            </thead>
            <tbody>
              {/*  Start*/}

            {
              addOns.starter.map((feature,index)=>{
                return<tr key={index}>
                  <td>{toTitleCase(feature)}
                  
                  </td>
                  
                
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
            
                 
                  
                

                </tr>

              })
            }

{
              addOns.standard.map((feature,index)=>{
                return<tr key={index}>
                  <td>{toTitleCase(feature)}
                  
                  </td>
                  
                
                  <td>
                  <FaWindowClose className='text text-danger'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
            
                 
                  
                

                </tr>

              })
            }

{
              addOns.premium.map((feature,index)=>{
                return<tr key={index}>
                  <td>{toTitleCase(feature)}
                  
                  </td>
                  
                
                  <td>
                  <FaWindowClose className='text text-danger'/>
                  </td>
                  <td>
                  <FaWindowClose className='text text-danger'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
            
                                 
                

                </tr>

              })
            }
        
          

             
            </tbody>
         
        </table>
      </div>
    )

}

export default PricingFeatures;
import React,{useState,useEffect} from "react";
import styled from "styled-components";
// Components
import {makeGetRequest} from "../../services/httpHelper"
import SubscriptionFeatureCard from "../cards/SubscriptionFeatureCard";
import PricingFeatures from "./PricingFeatures";
import { Helmet } from "react-helmet-async";






export default function Pricing() {
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('free');
  const [plans,setPlans] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePlanChange = (e) => {
    setPlan(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic here, e.g., sending data to your server
    console.log(`Subscribed with email: ${email} and plan: ${plan}`);
  };

  const getPlans = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest('subscription-plans');
    if (res && res.errorType) {
      //  setIsLoading(false);
        //const errorMessage = ErrorHandler(res)
       // setServerError(errorMessage)

    } else {
      const results = await res.data;
      
        setPlans(results)        
        setIsLoading(false);
        setServerError(null)
        console.log('plans',plans)
        

    }
   
}



  useEffect(()=>{
    getPlans();

  },[])
  return (
    <>
          <Helmet>
               <title>CS Church Management & Accounting Software Plans and Offers</title>
    <meta name="description" content="CS Church Management and Accounting Software offers the most competative prices in return of what you get. This is on top of free 30 days trial.
    "/>
     <link rel='canonical' href='/pricing'/>


  </Helmet>

  <Wrapper id="pricing">

<div className="whiteBg">
<div className="container">
<HeaderInfo>
            <h1 className="font30 extraBold" style={{textAlign:"left"}}>CS Church Management & Accounting Software Plans and Offers</h1>
            <p className="font13">
              All our plans you remain unlimited to number of church members you can add and number of users.
              <br />
              We understand that you are just getting up and our prices are favourable.
            </p>
          </HeaderInfo>

<div className='row'>
        {
           plans.length !== 0 ?
          plans.map((plan)=>{
            return <div className='col-md-4' key={plan.id}>
            <SubscriptionFeatureCard data={{plan:plan,features:[]}} showExcludedFeatures={plan.plan.toLowerCase()==='starter'?false:true}/>
    
            </div>
          })
          :
          <p className='text text-info'>No Results</p>
        }
        
      
      
      </div>
      <PricingFeatures/>
</div>
</div>


    </Wrapper>
    </>
   
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




